import { SetupContext, ref } from '@vue/composition-api';
import { getZones } from '@ax/api-clients-accounts/clients/zones.client';
import { ZoneWithCounts } from '@ax/api-clients-accounts/models';
import { DataTableState, DataTablePageState } from '@/utils/data-table-state';
import { DEFAULT_TABLE_PAGINATION } from '@/utils/constants';
import { QueryStateParametersConfiguration } from '@/utils/query-state';
import { QueryStateStorageConfig } from '@/utils/storage';
import { useRouter } from '@ax/vue-utils/router';

export function getZoneQueryStateConfiguration(): QueryStateParametersConfiguration<{}> {
  return {
    limit: { defaultValue: DEFAULT_TABLE_PAGINATION, storeInBrowser: true },
    page: { defaultValue: 1 },
  };
}

export function useZonesTable(
  context: SetupContext,
  queryStateParametersConfiguration?: QueryStateParametersConfiguration<
    DataTablePageState<ZoneWithCounts>
  >,
) {
  const router = useRouter();
  const zones = ref<ZoneWithCounts[]>([]);
  const totalServerZones = ref<number>();
  const queryStateConfig =
    queryStateParametersConfiguration || getZoneQueryStateConfiguration();
  const storageConfig: QueryStateStorageConfig = {
    key: 'ax-zones-prefs',
    store: localStorage,
  };

  function getZonesList(query: string): Promise<void> {
    return getZones(query)
      .then(({ data, metadata }) => {
        zones.value = data;
        totalServerZones.value = metadata.total_count;
      })
      .catch(() => {
        totalServerZones.value = 0;
      });
  }

  const { tableState, queryState, updateQueryState } =
    DataTableState.synchronize<
      ZoneWithCounts,
      DataTablePageState<ZoneWithCounts>
    >(router.currentRoute, router, queryStateConfig, {
      callback: ({ apiQuery }) => getZonesList(apiQuery),
      storageConfig,
    });

  return {
    zones,
    totalServerZones,
    tableState,
    queryState,
    updateQueryState,
    storageConfig,
  };
}
