var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ax-zones-table"},[_c('ax-table',{attrs:{"footer-props":_vm.zonesFooter,"headers":_vm.zonesHeaders,"items":_vm.zones,"column-select":false,"options":_vm.tableStateLocal,"server-items-length":_vm.serverZonesLength,"item-key":"id"},on:{"update:options":function($event){_vm.tableStateLocal=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"tw-inline-block tw-leading-tight",attrs:{"to":_vm.zoneEditorRoute(item.id)}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"header.organization_id",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('ax-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""}},on),[_vm._v(" "+_vm._s(_vm.mdiInformationOutline)+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('setup.zones.legacyId:tooltip'))+" ")])])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatTime")(item.created_at))+" ")]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatTime")(item.updated_at))+" ")]}},{key:"item.created_by",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_by.email)+" ")]}},{key:"item.user_count",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"tw-flex tw-justify-end"},[_vm._v(_vm._s(item.user_count))])]}},{key:"item.device_count",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"tw-flex tw-justify-end"},[_vm._v(_vm._s(item.device_count))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"tw-flex tw-justify-end"},[_vm._v(_vm._s(_vm.isDisabled(item) ? _vm.$t('general.statuses.disabled') : _vm.$t('general.statuses.enabled')))])]}},{key:"item.legacy_saml_enabled",fn:function(ref){
var item = ref.item;
return [(item.legacy_saml_enabled)?_c('router-link',{attrs:{"to":{ name: 'security', query: { o: item.organization_id } }}},[_vm._v(" "+_vm._s(_vm.$t('general.statuses.enabled'))+" ")]):[_vm._v(_vm._s(_vm.$t('general.statuses.disabled')))]]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('ax-table-row-actions-column',[_c('v-list-item',{attrs:{"to":_vm.zoneEditorRoute(item.id)}},[_vm._v(" "+_vm._s(_vm.$t('setup.zones.editZone:phrase'))+" ")])],1)]}},_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}})],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }